import {ActivatedRouteSnapshot, RouterStateSnapshot, Routes} from '@angular/router';
import {LessCoreAuthenticatedGuard} from "@lessname/less-core";
import {inject} from "@angular/core";

export const appRoutes: Routes = [
    {
        canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(LessCoreAuthenticatedGuard).canActivate(route, state)],
        loadComponent: () => import('./components/app-home.component').then(mod => mod.AppHomeComponent),
        title: 'Home',
        path: '',
    },
    {
        loadChildren: () => import('../me/me.routes').then(m => m.meRoutes),
        title: 'Me',
        path: 'me',
    },
    {
        canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(LessCoreAuthenticatedGuard).canActivate(route, state)],
        loadChildren: () => import('../site/site.routes').then(m => m.siteRoutes),
        path: 'site',
    },
    {
        canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(LessCoreAuthenticatedGuard).canActivate(route, state)],
        loadChildren: () => import('../license/license.routes').then(m => m.licenseRoutes),
        path: 'license',
    },
];
