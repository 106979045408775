export const environment = {
    production: false,
    basePreferences: {
        locale: 'nl_NL',
    },
    services: {
        'less-identity': {
            uri: 'https://identity.service.lessname.dev/',
            token: true,
        },
        'less-meta': {
            uri: 'https://meta.service.lessname.dev/',
            token: true,
        },
        'less-site-juggler': {
            uri: 'https://service.sitejuggler.dev/',
            token: true,
        },
    },
};
