import {bootstrapApplication} from '@angular/platform-browser';
import {AppComponent} from "./app/components/app.component";
import {provideRouter, TitleStrategy, withRouterConfig} from "@angular/router";
import {appRoutes} from "./app/app.routes";
import {LessCoreAuthenticatedGuard, LessCoreGuestGuard, LessCoreRpcService} from "@lessname/less-core";
import {HttpClient, provideHttpClient} from "@angular/common/http";
import {importProvidersFrom} from "@angular/core";
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {AppMissingTranslationHandler} from "./app/handlers/app-missing-translation.handler";

import {registerLocaleData} from "@angular/common";
import localeNl from '@angular/common/locales/nl';
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {AppTranslateTitleStrategy} from "./app/strategy/app-translate-title.strategy";
import {environment} from "./environments/environment";

registerLocaleData(localeNl, 'nl_NL');

bootstrapApplication(
    AppComponent,
    {
        providers: [
            {
                provide: 'env',
                useValue: environment,
            },

            provideHttpClient(),
            LessCoreRpcService,

            importProvidersFrom(
                TranslateModule.forRoot({
                    missingTranslationHandler: {
                        provide: MissingTranslationHandler,
                        useClass: AppMissingTranslationHandler,
                    },
                    loader: {
                        provide: TranslateLoader,
                        useFactory: (http: HttpClient) => {
                            return new TranslateHttpLoader(http, './assets/i18n/', `.json`);
                        },
                        deps: [HttpClient],
                    },
                }),
            ),

            LessCoreAuthenticatedGuard,
            LessCoreGuestGuard,
            provideRouter(
                appRoutes,
                withRouterConfig({
                    paramsInheritanceStrategy: 'always',
                }),
            ),

            {
                provide: TitleStrategy,
                useClass: AppTranslateTitleStrategy,
            },
        ],
    },
)
    .catch((err) => console.error(err));
