import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {RouterStateSnapshot, TitleStrategy} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {first} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AppTranslateTitleStrategy extends TitleStrategy {
    constructor(
        private readonly translateService: TranslateService,
        private readonly title: Title,
    ) {
        super();
    }

    override updateTitle(routerState: RouterStateSnapshot): void {
        const title = this.buildTitle(routerState);

        if (title !== undefined) {
            this
                .translateService
                .get(title)
                .pipe(first())
                .subscribe((text) => {
                    this.title.setTitle(text);
                });

        } else {
            console.warn(`'${routerState.url}' misses a title`);

            this.title.setTitle('SiteJuggler');
        }
    }
}